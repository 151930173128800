@mixin range-width($max: 0, $min: 0){
    @if $min == 0 {
        @media screen and (max-width: $max) {
            @content;
        }
    } @else {
        @media screen and (max-width: $max) and (min-width: $min) {
            @content;
        }
    }
}

@mixin range-height( $max: 0, $min: 0){
    @if $min == 0 {
        @media screen and (max-height: $max) {
            @content;
        }
    } @else {
        @media screen and (max-height: $max) and (min-height: $min) {
            @content;
        }
    }
}

@mixin  title ($color:$title-color){
	.title {
        padding: 20px 10%;
		position: relative;
        
        @include range-width(768px) {
            display: inline-block;
            margin: .6rem 0;
            padding: .6rem 0;
        }

		.main {
			color: $color;
			font-size: 1.6rem;

            @include range-width(375px) {
                font-size: 1.2rem;
            }
		}

		&:before {
			content: " ";
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			width: 9%;
            border-bottom: 1px solid $color;

            @include range-width(768px) {
                bottom: 0;
                width: 6rem;
            }

            @include range-width(375px) {
                width: 4rem
            }
		}

		.sub {
			color: $sub-color;
			font-size: .8rem;
			padding-left: .4rem;

            @include range-width(500px) {
                display:none;
            }
		}
	}
}