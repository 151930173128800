.banner {
    background-image: url('../images/topBg.jpg');
    background-size: cover;
    display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    padding-top: 83px;
    height: 340px;
    z-index: 0;

    @include range-width ($screen-md) {
        position: relative;
        padding-top: 77px;
    }

    @include range-width ($screen-sm) {
        padding-top: 48px;
        height: 300px;
    }
        
    .banner-title {
        color: #ffffff;
        font-size: 2.4rem;
        padding-bottom: .4rem;
        position: relative;
        text-align: center;

        @include range-width ($screen-sm) {
            font-size: 2rem;
            margin-top: 1rem;
        }

        &:after {
            border-bottom: 3px solid #f9bf3b;
            content: "";
            height: 1px;
            margin: 0 auto;
            position: absolute;
                right: 0;
                bottom: -5px;
                left: 0;
            width: 70%;
        }
    }

    .banner-nav {
        margin-top: 20px;

        .banner-nav-list {
            display: flex;
            list-style-type: none;;

            @include range-width ($screen-md) {
                flex-direction: column;
            }

            .banner-nav-item {
                font-size: 1rem;
                font-weight: 600; 
                padding: .6rem;

                @include range-width (848px, $screen-md) {
                    font-size: 13px;
                }

                &.icon-cheveron-right {
                    color: #f9bf3b;
                }
                
                .banner-nav-link {
                    color: #ffffff;
                    text-decoration: none;

                    &:hover {
                        color: #f9bf3b;
                    }
                }
            }
        }
    }
}

.banner-toggle-menu {
    width: 100%;
    margin: 0 auto;
    opacity: 0;
    position: fixed;
        top: -5rem;
        left: 0;
    text-align: left;
    transition: opacity 0.5s linear;
    z-index: -1;

    &.active {

        @include range-width ($screen-md) {
            z-index: 3;
            opacity: 1;
            top: 5rem;
        }

        @include range-width (768px) {
            top: 62px;
        }

        @include range-width (480px,438px) {
            top: 58px;
        }

        @include range-width (438px, 400px) {
            top: 54px;
        }

        @include range-width (400px, $screen-xs) {
            top: 52px;
        }

        @include range-width (380px, $screen-xs) {
            top: 48px;
        }
        
    }

    .banner-title {
        background-color: #333F4A;
        color: #f9bf3b;
        font-size: 1.4rem;
        padding: 1rem 10px;
        text-align: left;

        @include range-width ($screen-sm, $screen-xs) {
            font-size: 1rem;
            padding: 1rem 10px;
        }

        .icon-cheveron-down {
            font-size: 3rem;
            position: absolute;
                right: 5px;
                top: 5px;

            @include range-width ($screen-sm) {
                font-size: 2rem;
                right: 5px;
                top: 10px;
            }
        }

        .icon-cheveron-up {
            font-size: 3rem;
            position: absolute;
                right: 20px;
                top: 20px;

            @include range-width ($screen-sm) {
                font-size: 2rem;
                right: 5px;
                top: 5px;
            }
        }
    }

    .banner-nav {
        display: none;

        &.active {
            display: none;

            @include range-width ($screen-md) {
                display: block;
            }
        }
        
        .banner-nav-list {
            background-color: #333F4A;
            display: flex;
            flex-direction: column;
            list-style-type: none;
            opacity: .8;
            padding: 1rem 2rem;

            @include range-width ($screen-md) {
                padding: 10px;
            }
            
            .banner-nav-item {
                font-size: 1.4rem;
                padding: .6rem 0;
                
                @include range-width ($screen-sm) {
                    font-size: 1rem;
                }

                &:before {
                    content: '';
                }
                
                .banner-nav-link {
                    color: #ffffff;
                    text-decoration: none;

                    &:hover {
                        color: #f9bf3b;
                    }

                    &.active {
                        color: #f9bf3b;
                    }
                }
            }
        }
    }
}