@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?8sr3sn");
  src: url("../fonts/icomoon.eot?8sr3sn#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?8sr3sn") format("truetype"), url("../fonts/icomoon.woff?8sr3sn") format("woff"), url("../fonts/icomoon.svg?8sr3sn#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add:before {
  content: "\e902"; }

.icon-fax:before {
  content: "\e903"; }

.icon-icon01:before {
  content: "\e905"; }

.icon-icon02:before {
  content: "\e906"; }

.icon-icon03:before {
  content: "\e907"; }

.icon-icon04:before {
  content: "\e908"; }

.icon-mail:before {
  content: "\e909"; }

.icon-phone:before {
  content: "\e90a"; }

.icon-fb:before {
  content: "\e904"; }

.icon-cheveron-outline-left:before {
  content: "\e900"; }

.icon-cheveron-outline-right:before {
  content: "\e901"; }

.icon-close:before {
  content: "\e90b"; }

.icon-menu:before {
  content: "\e90c"; }

.icon-cheveron-down:before {
  content: "\e90e"; }

.icon-cheveron-right:before {
  content: "\e90d"; }

.icon-cheveron-up:before {
  content: "\e90f"; }
