.index-about {
    margin-top: 5rem;
    padding: 2rem 5rem;

    @include range-width (1024px, $screen-md) {
        margin-top: 3rem;
    }

    @include range-width ($screen-md) {
        margin-top: 2rem;
        padding: 10px;
    }

    @include range-width ($screen-sm) {
        margin-top: 1rem;
        padding: 10px;
    }

    .index-about-content {
        display: flex;
            align-items: flex-start;
            justify-content: space-between;

        @include range-width ($screen-md) {
            align-items: center;
            flex-direction: column;
        }

        .index-about-text {
            flex: 1;

            .index-about-text {
                line-height: 2.2rem;

                @include range-width ($screen-sm) {
                    line-height: 1.8rem;
                }

                .top {
                    font-size: 1.4rem;
                    text-align: justify;

                    @include range-width ($screen-sm) {
                        font-size: 1rem;
                    }

                    p {
                        @include range-width ($screen-sm) {
                            display: inline;
                        }
                    }
                }

                .top-en {
                    font-size: 1.4rem;

                    @include range-width ($screen-md) {
                        font-size: 1rem;
                        line-height: 1.6rem
                    }
                    
                    @include range-width ($screen-sm) {
                        font-size: 1rem;
                        line-height: 1.4rem;
                    }

                    p {
                        @include range-width ($screen-sm) {
                            display: inline;
                        }
                    }
                }

                .bottom {
                    color: #9ea2a5;
                    font-size: 1.2rem;  
                    font-weight: 400;  
                    line-height: 1.6rem;
                    margin-top: 2rem;

                    @include range-width ($screen-sm) {
                        font-size: .8rem;
                        line-height: 1rem;
                        margin-top: 1rem;
                    }

                    p {
                        @include range-width ($screen-sm) {
                            display: inline;
                        }
                    }
                }
                
                .focus {
                    color: #f9bf3b;
                }
            }

            .index-about-text-more {
                display: flex;
                justify-content: space-around;
                    align-items: center;
                margin: 15px 0;
                padding-left: 80%;
                position: relative;

                &:before {
                    content: " ";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 80%;
                    border-bottom: 1px solid #ff6c2b;

                    @include range-width ($screen-md) {
                        width: 80%;
                        text-align: right;
                    }
                    @include range-width ($screen-sm) {
                        width: 70%;
                        text-align: right;
                    }
                }

                .index-about-button {
                    flex: 1;
                    font-size: 1.4rem;
                    text-decoration: none;
                    padding-bottom: .2rem;
                    padding-left: .6rem;
                    position: relative;
                    color: #f9bf3b;
                    @include range-width ($screen-md) {
                        font-size: 1.4rem;
                    }                
                    
                    @include range-width ($screen-sm) {
                        font-size: 1rem;
                        padding-left: 0;
                    }
                }
            }
        }

        .index-about-img {
            flex: 1;
                align-self: center;

            @media screen and (min-width: $screen-hd){
                text-align: center;    
            }

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }
}

.index-service {
    display: flex;
    padding: 2rem 5rem;

    @include range-width ($screen-md) {
        flex-direction: column;
        padding: 10px;
    }

    @include range-width ($screen-sm) {
        padding: 0;
    }

    .index-service-item {
        flex: 1;
        line-height: 1rem;

        @include range-width ($screen-md) {
            display: flex;
            padding: .4rem 0;
        }

        @include range-width ($screen-sm) {
            padding: .4rem 10px;
        }

        .icon {
            color: #f9bf3b;
            font-size: 3rem;

            @include range-width ($screen-md) {
                display: flex;
                flex: 1;
                font-size: 3rem;
                justify-content: center;
                margin: 0 auto;
                padding: 0 2rem;
                width: 3rem;
            }

            @include range-width ($screen-sm) {
                padding: 0 10px;
                width: 50px;
            }

            .icon-icon01,.icon-icon02 {
                @include range-width ($screen-sm) {
                    font-size: 2.6rem;
                }
            }

            .icon-icon03,.icon-icon04 {
                @include range-width ($screen-md) {
                    font-size: 2rem;
                }
            }
        }

        .index-service-content {
            padding-right: 1rem;

            @include range-width ($screen-md) {
                align-self: flex-start;
                padding: 0 1rem;
            }

            @include range-width ($screen-sm) {
                padding: 0 .4rem;
            }

            .index-service-name {
                color: #9ea2a5;
                font-size: 1rem;
                padding: .8rem 0 .5rem 0;

                @include range-width ($screen-md) {
                    padding: .4rem 0 .5rem 0;
                    font-size: 1.2rem;
                }

                @include range-width ($screen-sm) {
                    font-size: 1rem;
                    padding: 0;
                }
            }

            .index-service-title {
                cursor: pointer;
                color: #09021c;
                font-size: 1rem;
                padding: .2rem 0;

                a {
                    color: #09021c;
                    text-decoration: none;

                    &:hover {
                        color: #ff6c2b;
                    }
                }
                
                @include range-width ($screen-md) {
                    font-size: 1.6rem;
                    padding: .6rem 0;
                }
                
                @include range-width ($screen-sm) {
                    font-size: 1rem;
                    padding: .2rem 0;
                }
            }

            .index-service-article {
                color: #9ea2a5;
                font-size: .8rem;
                line-height: normal;
                padding-top: 10px;

                @include range-width ($screen-md) {
                    font-size: 1rem;
                    padding: .2rem 0;
                }
                
                @include range-width ($screen-sm) {
                    font-size: .8rem;
                    line-height: 1.2;
                    padding: .4rem 0;
                    text-align: left;
                }
            }
        }
    }
}

.index-number {
    background-image: url('../images/numbg.jpg');
    background-size: cover;
    background-attachment: scroll;
    background-color: transparent;
    background-position: center center;
    display: flex;
        align-items: center;
    height: 285px;
    text-align: center;

    

    @include range-width ($screen-md) {
        height: 500px;
        flex-direction: column;
    }

    @include range-width ($screen-sm) {
        height: 400px;
        padding: 10px;
    }
    
    .index-num {
        color: #f9bf3b;
        flex: 1;
        font-size: 6rem;

        @include range-width (1080px, $screen-md) {
            font-size: 5rem;
        }

        @include range-width ($screen-sm) {
            font-size: 4rem;
        }

        .index-num-text {
            color: #ffffff;
            font-size: 1.2rem;
            padding-top: 10px;

            @include range-width (1080px, 885px) {
                font-size: 1rem;
            }

            @include range-width (885px, $screen-md) {
                font-size: .8rem;
            }

            @include range-width ($screen-sm) {
                font-size: 1rem;
            }
        }

        &:nth-child(2) {
            position: relative;

            &:before {
                content: '|';
                font-size: 1em;
                position: absolute;
                    top: 0;
                    left: 0;
            }
            &:after {
                content: '|';
                font-size: 1em;
                position: absolute;
                    top: 0;
                    right: 0;
            }
            @include range-width ($screen-md, $screen-xs) {
                &:before, &:after {
                    content: '';
                }
            }
        }
    }
}	

.index-ftz {
    background-image: url('../images/index_ftzbg.png');
    background-size: cover;
    background-attachment: scroll;
    background-color: transparent;
    background-position: right top;
    display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    position: relative;

    @include range-width ($screen-md) {
        background-color: #F3F3F3;
        padding: 10px;
    }

    .index-ftz-title {
        font-size: 1.6rem;
        margin: 1rem 0;
        padding: 2.6rem 0 1.6rem 0;
        position: relative;

        @include range-width ($screen-md) {
            font-size: 2rem;
            text-align: center;
        }

        @include range-width ($screen-sm) {
            font-size: 1.8rem;
            text-align: center;
        }

        &:after {
            border-bottom: 3px solid #f9bf3b;
            content: "";
            height: 1px;
            margin: 0 auto;
            position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
            width: 6rem;
        }
    }

    .index-ftz-content {
        font-size: 1.2rem;
        width: 80%;    
        
        @include range-width ($screen-md) {
            font-size: 1.2rem;
            width: 100%;
        }

        @include range-width ($screen-sm) {
            font-size: 1rem;
        }

        .index-ftz-article {
            line-height: 2rem;
            padding: 1rem 3rem;

            @include range-width ($screen-md) {
                line-height: 2rem;
                padding: 0;
            }

            @include range-width ($screen-sm) {
                font-size: 1rem;
                line-height: 1.4rem;
            }
        }

        .index-ftz-img {
            padding: 0;
            text-align: center;

            img {
                max-width: 100%;
            }
        }
    }
}

.index-contact {
    padding: 2rem 5rem;

    @include range-width ($screen-sm) {
        padding: 10px;
    }
    
    .index-contact-title {
        display: inline-block;
        font-size: 1.6rem;
        margin: 1.2rem 0;
        padding: .6rem 0;
        position: relative;
        
        &:after {
            border-bottom: 3px solid #f9bf3b;
            content: "";
            height: 1px;
            margin: 0 auto;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
        }
    }
    .index-contact-content {
        display: flex;
            align-self: center;
        @include range-width ($screen-md) {
            align-items: center;
            flex-direction: column;
        }

        .index-contact-item {
            flex: 1;
            display: flex;
                flex-direction: column;
                justify-content: flex-start;

            @include range-width (1080px, $screen-md) {
                margin: .4rem;
                text-align: left;
            }

            @include range-width ($screen-md) {
                align-items: center;
                flex-direction: column;
                margin: 1rem 0;
            }

            .index-contact-name {
                font-size: 1.2rem;
                height: 3rem;

                @include range-width ($screen-md) {
                    font-size: 2rem;
                    height: auto;
                    margin: 1rem 0;
                }

                @include range-width ($screen-sm) {
                    font-size: 1.4rem;
                    margin: 0;
                }
            }
            

            .index-contact-map {
                align-self: center;
                position: relative;
                width: 100%;

                @include range-width ($screen-md) {
                    text-align: center;
                }

                iframe {
                    @include range-width ($screen-md) {
                        width: 100% !important ;
                    }
                    
                }
            }

            .index-contact-info {
                font-size: 1rem;

                @include range-width ($screen-md) {
                    font-size: 1.2rem;
                    margin: 1rem 0;
                }

                @include range-width ($screen-sm) {
                    font-size: 1rem;
                    margin: 0;
                }

                & div {
                    padding: 10px 0;
                    display: flex;

                    .icon {
                        color: #f9bf3b;
                        display: inline-block;
                        font-size: 1.2rem;
                        width: 2rem;
                        text-align: left;

                        &:after {
                            padding-right: 10px;
                        }
                    }
                }

                .mail {
                    cursor: pointer;

                    a {
                        color: #09021c;
                        text-decoration: none;

                        &:hover {
                            color: #f9bf3b;
                        }
                    }

                    &:hover {
                        color: #f9bf3b;

                        a {
                            color: #f9bf3b;
                        }
                    }
                }
            }

            .index-contact-fb {
                position: relative;
                display: flex;
                    justify-content: center;
                    align-items: center;
                
                @include range-width ($screen-md) {
                    width: 300px;
                }

                @include range-width ($screen-sm) {
                    width: 300px;
                }

                .fb-comments, .fb-comments iframe[style] {
                    width: 100% !important;
                    margin: 0 auto;
                }

                .fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
                    width: 100% !important;
                }
            }
        }
    }
}