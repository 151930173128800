.contact {
    padding: 2rem 5rem;
    position: relative;

    @include range-width ($screen-md) {
        padding: 1rem 2rem;
    }

    @include range-width ($screen-sm) {
        padding: 10px;
    }

    @include title;

    .contact-content {
        padding: 0 10%;

        @include range-width ($screen-md) {
            padding: 0;
        }

        .contact-item {
            display: flex;
            margin-top: 2rem;

            @include range-width ($screen-md) {
                flex-direction: column;
            }

            &:nth-child(odd) {
                flex-direction: row-reverse;

                @include range-width ($screen-md) {
                    flex-direction: column;
                }
            }  

            

            .info {
                background-color: #f6f6f6;
                flex: 1;
                max-height: 300px;
                overflow: hidden;
                padding: 2rem;

                @include range-width ($screen-md) {
                    
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                }

                @include range-width ($screen-sm) {
                    font-size: .8rem;
                    padding: 2rem .8rem;
                }

                & > * {
                    padding-top: 1rem;
                }

                .name {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    margin-bottom: .8rem;
                    padding-top: 0;

                    @include range-width ($screen-md) {
                        font-size: 1.6rem;
                        line-height: 2rem;
                    }
                    
                    @include range-width ($screen-sm) {
                        font-size: 1.2rem;
                        line-height: 1.2rem;
                    }
                }

                .mail {
                    a {
                        color: #000000;
                        text-decoration: none;

                        .hover-color {
                            &:hover {
                                color: #ff6c2b;
                            }
                        }   
                    }
                }

                .map-link {
                    border: 1px solid #000000;
                    border-radius: .2rem;
                    color: #000000;
                    display: inline-block;
                    font-size: 1.2rem;
                    margin-top: 1rem;
                    padding: .6rem 2rem;
                    text-align: center;
                    text-decoration: none;   
                    
                    &:hover {
                        color: #ff6c2b;
                        border: 1px solid #ff6c2b;
                    }
                }
            }

            .map {
                flex: 1;
                iframe {
                    width: 100%;
                }
            }
        }
    }
}