.team {
    padding: 2rem 5rem;


    @include range-width ($screen-md) {
        padding: 10px;
    }

    @include title;

    .team-content {
        padding: 0 10%;

        @include range-width ($screen-sm) {
            padding: 0;
        }

        .team-area {
            margin: 1rem 0;

            .area-name {
                font-size: 1.2rem;
                padding: .8rem  0;
            }

            .team-member {

                display: flex;
                    justify-content: flex-start;

                @include range-width (1024px, $screen-md) {
                        justify-content: space-between;
                        flex-wrap: wrap;
                }

                @include range-width ($screen-md) {
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                @include range-width ($screen-sm) {
                    justify-content: space-between;
                }

                figure {
                    flex-basis: 25%;

                    @include range-width (1024px, $screen-md) {
                        flex-basis: 35%;
                        padding-right: 0;
                        width: auto;
                    }

                    @include range-width ($screen-md) {
                        flex-basis: 35%;
                        padding-right: 0;
                        width: auto;
                    }
                    @include range-width ($screen-md) {
                        flex-basis: 45%;

                    }

                    .member-img {
                        height: auto;
                        max-width: 100%;
                    }

                    .member-info {
                        padding: .6rem 0;
                        text-align: center;
                        width: 210px;
                        @include range-width ($screen-sm) {
                            width: auto;
                            
                        }

                        .name {
                            font-size: 1.4rem;

                            .job {
                                color: #9ea2a5;
                                font-size: .8rem;
                                
                                @include range-width ($screen-md) {
                                    display: block;
                                }
    
                                &:before {
                                    content:' / ';
                                    
                                    @include range-width ($screen-md) {
                                        content: ' ';
                                    }
                                }
                            }
                        }

                        .icon-mail {
                            color: #ff6c2b;
                            display: inline-block;
                            margin: .6rem 0;
                            transition: font-size .1s ease-in;

                            @include range-width ($screen-sm) {
                                width: auto;
                            }

                            &:hover {
                                font-size: 1.2rem;
                                transition: font-size .1s ease-in;
                            }
                        }
                    }
                }
            }
        }
    }
}