
header {
    background-color: transparent;
    display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    font-size: 1rem;
    max-width: 100%;
    padding: 2rem 5rem;
    position: absolute;
        top: 2rem;
        left: 0;
    transition: background-color 0.5s linear;
    width: 100%;
    z-index: 1;

    @include range-width($screen-md) {
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
            top: 0;
    }

    @include range-width($screen-sm) {
        padding: 10px;
    }

    @include range-height(550px) {
        top: 0;
    }

    .logo {
        flex: 1;

        @include range-width($screen-md) {
            flex: 2;
        }

        .logo-link {
            cursor: pointer; 

            .logo-img {

                img {
                    height: auto;
                    max-width: 355px;
                    width: 100%;

                    @include range-width($screen-sm) {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .menu {
        flex: 2;
        
        @include range-width($screen-md) {
            display: none;
        }

        .lang-list {
            display: flex;
                align-items: center;
                justify-content: flex-end;
            list-style-type: none;
            padding-bottom: 1rem;

            @include range-width(1024px, 769px) {
                padding-bottom: .4rem;
            }

            .lang-link {
                background-color: transparent;
                border: 0;
                color: #ffffff;
                font-size: 0.8rem;
                font-weight: 600;
                margin-left: 1rem;
                outline: none;

                

                &.active {
                    background-color: $title-color;
                    border-radius: .8rem;
                    color: #ffffff;
                    height: 1.4rem;
                    line-height: 1.4rem;
                    width: 2.4rem;

                    @include range-width(1024px, 769px) {
                        height: 1rem;
                        line-height: 1rem;
                        width: 2rem;
                    }
                    
                    &:hover {
                        color: #ffffff;
                    }
                }

                &:hover {
                    color: #ff6c2b;
                }

                .icon {
                    text-decoration: none;
                    color: #ffffff;

                    .icon-fb {
                        font-size: 1.2rem;

                        @include range-width(1024px, 769px) {
                            font-size: 1rem;
                        }

                        &:hover {
                            color: #ff6c2b;
                        }
                    }
                }
            }
        }

        .menu-list {
            display: flex;
                justify-content: flex-end;
            list-style-type: none;

            .menu-item {
                font-size: 1rem;
                margin-left: 1.4rem;
                text-align: right;

                @include range-width(1024px, 769px) {
                    font-size: 12px;
                    margin-left: 1rem;
                }

                .menu-link {
                    color: #ffffff;
                    text-decoration: none;

                    &:hover {
                        color: #ff6c2b;
                    }
                }
            }
        }
    }

    .mobile-menu {
        background: #303C47;
        height: 100%;
        
        overflow-x: hidden;
        padding-top: 60px;
        position: fixed;
            top: 0;
            right: 0;
        transition: 0.5s;
        width: 0;

        &.active {
            width: 0;
            @include range-width($screen-md) {
                width: 100%;
            }
        }

        .lang-list {
            display: none;
            list-style-type: none;
            padding: 1rem 0;;
            
            @include range-width($screen-md) {
                display: flex;
                    align-items: center;
                    justify-content: flex-start;
            }

            .lang-link {
                background-color: transparent;
                border: 0;
                color: #ffffff;
                font-size: 1.4rem;
                font-weight: 600;
                margin-left: 1rem;
                outline: none;
                
                @include range-width($screen-sm) {
                    font-size: 1rem;
                }

                &.active {
                    background-color: #ff6c2b;
                    border-radius: 1rem;
                    height: 2rem;
                    line-height: 2rem;
                    width: 4rem;
                }
                
                a {
                    display: none;
                    
                    &.icon-fb {
                        display: none;
                    }
                }
                
            }
        }

        .menu-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
            
            .menu-item {
                margin: 0;
                padding: 0;

                .menu-link {
                    color: #ffffff;
                    display: block;
                    font-size: 1.6rem;
                    padding: 1rem;
                    text-decoration: none;

                    @include range-width($screen-sm) {
                        font-size: 1rem;
                    }

                    &.active {
                        background: darken(#303C47, 10%);
                        color: darken(#303C47, 10%);
                        transition: transform .25s ease-in-out;
                    }
                }
            }
        }
    }

    .toggle-menu {
        display: none;

        @include range-width($screen-md) {
            flex: 1;
            display: flex;
                justify-content: flex-end;
            outline: 0;
            outline-color: transparent;
            z-index: 5;
        }

        .icon-menu,.icon-close {
            color: #ffffff;
            font-size: 2.4rem;

            @include range-width($screen-sm) {
                font-size: 1.6rem;
            }
        }
       
    }

    &.top {
        background-color: #33404b;
        padding: 1rem 5rem;
            top: 0;

        @include range-width($screen-md) {
            padding: 10px;
        }
    }
    
    &.fix-header {
        background-color: #33404b;
        opacity: .9;
        position: fixed;
            top: 0;
            left: 0;
        transition: background-color 0.5s linear;

        .mobile-menu {
            @include range-width($screen-md) {
                opacity: 1;
            }
        }
    }
}

