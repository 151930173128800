.taoyuan-ftz-title {
    padding: 2rem 5rem;

    @include range-width ($screen-md) {
        padding: 10px;
    }

    @include range-width ($screen-sm) {
        padding: 10px;
    }

    @include title;
}

#taoyuanFtz {
    background-color: transparent;
    position: relative;
    
    .taoyuanFtz-content {
        background-size: cover;
        max-width: 100%;
        height: 100%;
        position: relative;

        // @include range-width ($screen-sm) {
        //     padding: 0 10px;
        // }

        .img360 {
            height: 640px;

            @include range-width ($screen-sm) {
                height:400px;
            }

            iframe {
                border: 0;
            }
        }

        .taoyuanFtz-list {
            background-color: #f9bf3b;
            padding: 2rem;
            position: absolute;
                top: 0;
                left: calc(10% + 4rem);
                right: 0;
            width: 35%;

            @include range-width ($screen-md) {
                left: 5rem;                
                width: 55%;
            }

            @include range-width ($screen-sm) {
                position: relative;
                    left: 0;                
                width: 100%;
            }
            
            li {
                font-size: 1.2rem;
                padding: 1rem;

                @include range-width ($screen-sm) {
                    font-size: 1rem;
                    padding: .4rem;
                }

                p {
                    font-weight: 400;
                    margin: .4rem 0;
                }
            }
        }
    }
}

#partner {
    padding: 2rem 5rem;

    @include range-width ($screen-md) {
        padding: 10px;
    }

    @include title;

    .partner-imgs {
        display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        margin: 0 auto;
        width: 80%;

        @include range-width ($screen-md) {
            margin-top: 2rem;
            width: 100%;
        }

        @include range-width ($screen-sm) {
            flex-direction: column;
        }

        figure {
            border: 2px solid #ededed;
            flex-basis: 30%;
            margin-bottom: 2rem;
            @include range-width ($screen-sm) {
                text-align: center;
            }

            img {
                max-width: 100%;
            }
        }
    }
}

#whyChooseUs {
    padding: 2rem 5rem;

    @include range-width ($screen-md) {
        padding: 10px;
    }

    @include title;

    .chooseUs-title {
        font-size: 1.6rem;
        margin: 0 auto;
        width: 80%;

        @include range-width ($screen-md) {
            font-size: 1rem;
        }

        @include range-width ($screen-sm) {
            width: 100%;
        }
    }

    .article-content-img {
        text-align: center;
        
        img {
            width: 100%;
        }
    }

    .article-content-text {
        font-weight: 400;
        font-size: 1rem;
        margin: 1rem;

        ul {
            list-style: none; 
            
            li {
                padding: .6rem 0;
                text-align: left;

                &:before {
                    color: #f9bf3b; 
                    content: "\2022";  
                    display: inline-block; 
                    font-weight: bold; 
                    margin-left: -1rem; 
                    width: 1rem; 
                }
            }
        }
    }
}