$screen-xs: 320px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1280px;
$screen-hd: 1440px;

// // iphone 5 width
// $screen-5-w: 320px;

// // iphone 8 width
// $screen-8-w: 375px;

// // iphone 8plus width
// $screen-8p-w: 414px;

// // ipad width
// $screen-p-w: 768px;

$font-size: 15px;
$font-weight: 600;
$font-family: 'Noto Sans TC', sans-serif;
$font-family-en: 'Roboto', sans-serif;

$title-color: #ff6c2b;
$sub-color: #9ea2a5;
