* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: $font-size;
	font-weight: $font-weight;
}

body {
    font-family: 'Noto Sans TC', sans-serif;
    height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.App {
	position: relative;
}
