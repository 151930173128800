.right-dots-container {
    list-style-type: none;
    position: fixed;
        top: 60%;
        right: 1rem;
    z-index: 1;
    
    @include range-width ($screen-md) {
        display: none;
    }
    
    .right-dot {
        color: #f9bf3b;
        cursor: pointer;
        opacity: .5;
        padding: .4rem;
        position: relative;

        &:hover {
            opacity: 1;
        }

        .dots-name {
            color: #797e81;
            font-size: .8rem;
            padding-right: 1.2rem;
            text-decoration: none;

            &:after {
                background-color: #f9bf3b;
                border:1px solid #f9bf3b;
                border-radius: 50%;
                content: ' ';
                height: 1rem;
                position: absolute;
                    top: calc(50% - .6rem);
                    right: 0;
                width: 1rem;
            }

            &.active {
                opacity: 1;
                color: #f9bf3b;
            }  

            .d-none {
                display: none;
            }
        }

        &.active {
            opacity: 1;
        }  
    }
}