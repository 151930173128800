footer {
    background-color: #343a40;
    margin-top: 50px;
    padding: 1rem 5rem;

    @include range-width ($screen-md) {
        padding: 2rem;
    }

    @include range-width ($screen-sm) {
        padding: 10px;
    }

    .footer-content {
        display: flex;
            align-items: center;
        
        .logo {
            flex: 1;
            @include range-width ($screen-md) {
                display: none;
            }

            .logo-link {
                cursor: pointer; 

                .logo-img {

                    img {
                        height: auto;
                        max-width: 355px;
                        width: 100%;
    
                        @include range-width($screen-sm) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .menu {
            flex: 2;

            .menu-nav {
                display: flex;
                    justify-content: flex-end;
                list-style-type: none;
                margin-bottom : .4rem;
                @include range-width ($screen-md) {
                    display: none;
                }

                .menu-item {
                    padding-left: 1rem;
                    text-align: right;
                    
                    
                    .menu-link {
                        color: #ffffff;
                        font-size: 1rem;
                        text-decoration: none;

                        @include range-width(1024px, 769px) {
                            font-size: 12px;
                        }											

                        &:hover {
                            color: #ff6c2b;
                        }
                    }
                }
            }
            
            .toggle-menu {
                display: none;
            }

            .menu-mobile {
                display: none;
            }

            .copy {
                color: #ffffff;
                font-size: 1rem;
                text-align: right;

                @include range-width(1024px, 769px) {
                    font-size: 12px;
                }

                @include range-width ($screen-md) {
                    font-size: .8rem;
                    text-align: center;
                }

                @include range-width ($screen-sm) {
                    font-size: 0.6rem;
                }
            }     
        }
    }
}