.slider{
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
    
    .wrapper {
        transition: .5s all linear;
        -webkit-transition: .5s all linear;
        will-change: transform;

        .each-slide {
            background-attachment: scroll;
            background-color: transparent;
            background-position: center center;
            background-size: cover;
            display: none;
            font-size: 40vh;
            height: 100vh;
            text-align: center;
            width: 100vw;

            @include range-width ($screen-md) {
                background-position: 30% center;
                width: auto;
                max-width: 100%;
                max-height: 100%;
            }
            
            &.active {
                display: block;
            }
        }
    }

    .slider-container {
        color: #ffffff;
        font-size: 3rem;
        letter-spacing: 1px;
        line-height: 4rem;
        position: absolute;
            top: 30%;
            left: 13%;
        text-transform: uppercase;
        will-change: transform;

        @include range-width ($screen-md) {
            top: 30%;
            left: 10%;
            font-size: 2.6rem;
            line-height: 3.4rem;
        }
        
        @include range-width ($screen-sm) {
            top: 35%;
            left: 10%;
            font-size: 2.6rem;
            line-height: 3.4rem;
        }
        @include range-width (400px) {
            font-size: 2.4rem;
            line-height: 3.4rem;
        }

        @include range-width ($screen-xs) {
            top: 40%;
            font-size: 2rem;
            left: 10px;
            line-height: 2.4rem;
        }

        @include range-height (500px, 430px) {
            font-size: 2.8rem;
            line-height: 3.4rem;
        }

        @include range-height (430px, 320px) {
            font-size: 2.6rem;
            line-height: 3.2rem;
        }

        .text {
            opacity: 0;
            position: relative;

            &.active {
                opacity: 1;
                transition: opacity 1s ease-in;
                
                .slider-more {
                    display: inline-block;
                }
            }

            .slogan {
                color: #ffffff;
            }

            .slogan-en {
                color: #ffffff;

                @include range-width ($screen-md) {
                    font-size: 2.6rem;
                    line-height: 3.4rem;
                }
                
                @include range-width ($screen-sm) {
                    font-size: 2.6rem;
                    line-height: 3.4rem;
                }
                @include range-width (400px, 375px) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                }
        
                @include range-width (375px, $screen-xs) {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }

                @include range-height (490px, 410px) {
                    font-size: 2.2rem;
                    line-height: 2.6rem;
                }

                @include range-height (410px, 375px) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                }
                
                @include range-height (375px, 320px) {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                }
            }
            
            .slogan-color {
                color: #ff6c2b;
            }

            .sm-slogan {
                &:before {
                    content: '，';
                }

                @include range-width ($screen-sm) {
                    display: block;
                    &:before {
                        content: '';
                    }    
                }
            }

            .slider-more {
                border: 1px solid #ffffff;
                border-radius: 5px;
                color: #ffffff;
                display: none;
                font-size: 2rem;
                margin-top: 80px;
                padding: 5px 40px;
                text-decoration: none;
                
                @include range-width ($screen-sm, $screen-xs) {
                    font-size: 20px;
                    line-height: 35px;
                    margin-top: 10%;
                    padding: 5px 30px;
                }

                @include range-height (390px, 370px) {
                    margin-top: 50px;
                }

                @include range-height (370px, 360px) {
                    margin-top: 40px;
                }

                @include range-height (360px, 340px) {
                    margin-top: 30px;
                }

                @include range-height (340px, 320px) {
                    margin-top: 10px;
                }
                

                &:hover {
                    color: #ff6c2b;
                    border: 1px solid #ff6c2b;
                }
            }
        }
    } 

    .dots-container{
        height: auto;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
            bottom: 5%;
            left: 13%;
        width: auto;
        
        @include range-width ($screen-md) {
            left: calc(50% - 2.6rem);	
        }

        @include range-height (550px, 320px) {
            left: calc(50% - 2.6rem);	
        }

        li{
            display: inline-block;
            padding: 5px;
            
            &.active{
                button {
                    background-color: #ffffff;    
                }
            }
    
            button {
                background-color: transparent;
                border: 1px solid #ffffff;
                border-radius: 50%;
                width: 1rem;
                height: 1rem;
                outline: none;

                &:hover{
                    cursor: pointer;
                    opacity: .7;
                    text-decoration: none;
                }
            }
        }
    }
    
    .control-button {
        height: auto;

        .arrows {
            background: none;
            border: 0;
            color: #fefefe;
            cursor: pointer;
            font-size: 55px;
            position: absolute;
                top: 50%;
            outline: none;
            transition: ease .3s all;

            @include range-width ($screen-md) {
                display: none;	
            }

            &:hover{
                color: #ff6c2b;
            }

            &.prev {
                left: 15px;
            }
        
            &.next {
                right: 30px;
            }
        }
    }  
}