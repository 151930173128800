.article {
    background-color: transparent;
    padding: 2rem 5rem;

    @include range-width ($screen-sm) {
        padding: 10px;
    }
    
    @include title;

    .article-content {
        display: flex;
            justify-content: space-around;
        margin: 0 auto;
        position: relative;  
        width: 80%;

        @include range-width ($screen-md) {
            flex-direction: column;
            width: 100%;
        }
        
        &.about {
            .focus {
                margin-bottom: 2rem;
            }

            .normal {
                margin-bottom: 1rem;
            }

            .article-content-img {
                padding-bottom: 10px;
            }
        }

        &.services {
            padding: 0 15px;

            @include range-width ($screen-md) {
                flex-direction: column-reverse;
            }

            @include range-width ($screen-md) {
                padding: 10px;
            }

            .normal {
                font-size: 14px;
                font-weight: 200;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.6;
                letter-spacing: normal;
                margin-top: 2rem;
                text-align: justify;
                color: #333333;
            }

            .normal-en {
                font-size: 14px;
                font-weight: 200;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.6;
                letter-spacing: normal;
                padding: 1rem 0;
                color: #333333;
            }
            

        }

        &.ftz {
            margin: 0 0 1rem 0;
            padding: 0 0 1rem 0;
        }

        .article-content-text {
            flex: 1;

            .focus {
                font-size: 25px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.36;
                letter-spacing: normal;
                color: #09021c;

                b {
                    font-weight: 600;
                }
            }

            .normal {
                font-size: 15px;
                font-weight: 200;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: justify;
                text-justify:inter-word;
                color: #333333;
                @include range-width ($screen-sm) {
                    font-size: 12px;
                }
            }

            .normal-en {
                font-size: 15px;
                font-weight: 200;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: left;
                color: #333333;

                @include range-width ($screen-sm) {
                    font-size: 12px;
                } 

            }

            
        }

        .article-content-img {
            align-self: flex-start;
            flex: 1;
            width: 100%;
            height: 100%;
            text-align: center;
            @include range-width ($screen-sm) {
                text-align: center;
                align-self: flex-end;
            }  
            
            img {
                max-width: 100%;
            }
            
            .right {
                background-color: #f6f6f6;
                position: absolute;
                    top: 0;
                    right: 0;
                width: 80%;
                height: 100%;
                z-index: -1;

                @include range-width ($screen-sm) {
                    width: 100%;
                    top: 5%;
                }
            }

            .left {
                background-color: #f6f6f6;
                position: absolute;
                    top: 0;
                    left: 0;
                width: 80%;
                height: 100%;
                z-index: -1;
            }

            .bottom {
                background-color: #f6f6f6;
                height: 70%;
                position: absolute;
                    left: 0;
                    bottom: 0;
                width: 100%;
                z-index: -1;
                @include range-width ($screen-sm) {
                    height: 100%;
                }
            }
        }
    }

    .reverse {
        flex-direction: row-reverse;
        padding-top: 1rem;

        @include range-width ($screen-md) {
            flex-direction: column-reverse;
        }

        .article-content-text {
            padding: 0 15px;

            @include range-width ($screen-sm) {
                padding: 0;
            }
        }
    }
}



.article-en {
    background-color: transparent;
    padding: 2rem 5rem;

    @include range-width ($screen-md) {
        padding: 10px;
    }

    @include title;

    .article-content {
        display: flex;
            flex-direction: column-reverse;
        margin: 0 auto;
        position: relative;
        width: 80%;

        @include range-width ($screen-md) {
            margin: 0;
            width: 100%;
        }
        
        .article-content-text {
            font-size : 1.2rem;
            font-weight: 400;
            flex: 1;	
            line-height: 1.6rem;
            margin: 1rem 0;
            padding: 1rem 0;

            @include range-width ($screen-sm) {
                margin: 0;
                padding: 0;
                font-size: 1rem;
                text-align: left;
            }

            span {
                p {
                    margin: 1rem 0;
                }
            }

            &.services {
                padding: 0 15px;
            }

            &.ftz {
                margin: 0 0 1rem 0;
                padding: 0 0 1rem 0;
            }
        }

        .article-content-img {
            align-self: center;
            flex: 1;
            padding: 1rem 0;
            position: relative;
            text-align: center;
            width: 100%;

            @include range-width ($screen-md) {
                align-self: center;
            }

            @include range-width ($screen-sm) {
                margin: .4rem 0;
                padding: 0;
            }
            
            &:after {
                background-color: #f6f6f6;
                position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
            }

            img {
                max-width: 100%;
            }

            .right {
                background-color: #f6f6f6;
                height: 100%;
                position: absolute;
                    top: 0;
                    right: 0;
                width: 80%;
                
                z-index: -1;

                @include range-width ($screen-sm) {
                    width: 100%;
                    bottom: 0;
                }
            }

            .left {
                background-color: #f6f6f6;
                height: 100%;
                position: absolute;
                    top: 0;
                    left: 0;
                width: 80%;
                z-index: -1;
            }

            .bottom {
                background-color: #f6f6f6;
                height: 50%;
                position: absolute;
                    bottom: 0;
                    left: 0;
                width: 100%;
                z-index: -1;

                @include range-width ($screen-sm) {
                    height: 100%;
                }
            }
        }
    }

    .reverse {
        flex-direction: column;
        margin-top: 1rem;
    }
}