.history {
    background-image: url('../images/about/historyBg.jpg');
    background-size: cover;
    height: 600px;
    padding: 2rem 5rem;

    @include range-width (1024px) {
        height: 100%;
    }

    @include range-width ($screen-md) {
        height: 100%;
        padding: 10px;
    }

    @include title(#ffffff);

    .title {
        &:before {
            @include range-width ($screen-md) {
                width: 4rem;
            }
        }
    }

    .history-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 100%; 
        padding: 1rem 10%;

        @include range-width ($screen-md) {
            flex-wrap: nowrap;
            height: 100%;
            padding: 1rem 0;
        }

        .item {
            display: flex;
                align-items: baseline;
                justify-content: space-between;
            padding: 15px 0;
            
            .year {
                color: #f9bf3b;
                flex: 1;
                font-size: 2rem;

                @include range-width ($screen-sm) {
                    font-size: 1.6rem;
                }
                
                span {
                    border-bottom: 2px solid #f9bf3b;    
                }
            }

            .text {
                flex: 4;

                @include range-width ($screen-sm) {
                    flex: 3;
                }

                .text-item {
                    align-self: center;
                    color: #fff;
                    max-width: 400px;
                    padding-left: .8rem;
                    width: 100%;

                    @include range-width (1200px, 1024px) {
                        max-width: 350px;
                    }
                    
                    @include range-width ($screen-md) {
                        max-width: 100%;
                    }

                    @include range-width ($screen-sm) {
                        font-size: 12px;
                        text-align: left;
                    }

                    li {
                        line-height: 1.2rem;
                        padding: .2rem 0;
                    }
                }
            }
        }
    }
}